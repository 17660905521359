import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface SelectInputProps {
  onValueChange?: (e: any) => void;
  defaultValue?: string | any;
  children: React.ReactNode;
  placeholder: string;
  icon?: React.ReactNode;
}

const MySelectInput: React.FC<SelectInputProps> = (props) => {
  const { onValueChange, defaultValue, placeholder, children, icon } = props;
  return (
    <div className="relative mt-2">
      <span className="w-4 h-4 absolute top-3 start-4">{icon}</span>
      <Select onValueChange={onValueChange} defaultValue={defaultValue}>
        <SelectTrigger className="w-full py-2 px-10 h-10 bg-transparent rounded outline-none border border-gray-200 focus:border-indigo-600 focus:ring-0">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{placeholder}</SelectLabel>
            {children}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default MySelectInput;
