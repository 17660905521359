import Image from "next/image";

interface DetailArticlesProps {
  image: string;
  alt: string;
  content: React.ReactNode;
  title?: string;
  author?: string;
  author_image?: string;
  created_at?: string;
}

const DetailArticles: React.FC<DetailArticlesProps> = (props) => {
  const { image, alt, content, title, author, created_at, author_image } =
    props;
  return (
    <>
      <div className="w-full h-[500px]">
        <Image
          src={image}
          alt={alt}
          width={100}
          height={100}
          className="w-full h-full object-cover"
          loading="lazy"
        />
      </div>
      <div className="mt-6">
        <h2 className="lg:text-4xl text-xl text-center font-bold">{title}</h2>
        <div className="text-slate-400 p-3">{content}</div>
        {(author || created_at || author_image) && (
          <div className="mb-2 border-t border-slate-700">
            <div className="flex items-center">
              <div className="mr-2 overflow-hidden w-[40px] h-[40px] rounded">
                {author_image && (
                  <Image
                    src={author_image}
                    alt={`image of ${author}`}
                    width={40}
                    height={40}
                    className="rounded-full h-full object-cover"
                  />
                )}
              </div>
              <div>
                {author && (
                  <h5 className="text-sm font-semibold mb-0">{author}</h5>
                )}
                {created_at && (
                  <span className="text-slate-400 text-xs">{created_at}</span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailArticles;
