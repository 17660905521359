import Articles from "./(Articles)/Articles";
import DetailArticles from "./(Articles)/DetailArticles";
import MyButton from "./Button";
import MyCard from "./Card";
import Title from "./Title";
import Popup from "./Popup";
import MyLabel from "./Label";
import MyInput from "./Input";
import MyTextArea from "./TextArea";
import WhatsappPopup from "./Whatsapp";
import RunningText from "./RunningText";
import MySelectInput from "./SelectInput";
import PaginationComponent from "./Pagination";

const Core = {
  Button: MyButton,
  Card: MyCard,
  Title: Title,
  Popup: Popup,
  Label: MyLabel,
  Input: MyInput,
  Articles: Articles,
  DetailArticles: DetailArticles,
  TextArea: MyTextArea,
  WhatsappPopup: WhatsappPopup,
  RunningText: RunningText,
  Select: MySelectInput,
  Pagination: PaginationComponent,
};

export default Core;
