"use client";

import { useState, useEffect } from "react";

const RunningText = () => {
  const [text, setText] = useState("");
  useEffect(() => {
    const phrases = ["MacBook", "iMac", "Mac-Mini", "Mac-Pro"];
    let currentPhrasesIndex = 0;
    let currentCharIndex = 0;
    let isDeleting = false;
    let typeSpeed = 150;

    const type = () => {
      const currentText = phrases[currentPhrasesIndex];
      setText(
        isDeleting
          ? currentText.substring(0, currentCharIndex)
          : currentText.substring(0, currentCharIndex + 1)
      );

      if (!isDeleting && currentCharIndex === currentText.length) {
        isDeleting = true;
        typeSpeed = 270;
      } else if (isDeleting && currentCharIndex === 0) {
        isDeleting = false;
        currentPhrasesIndex = (currentPhrasesIndex + 1) % phrases.length;
        typeSpeed = 100;
      }

      currentCharIndex += isDeleting ? -1 : 1;

      setTimeout(type, typeSpeed);
    };

    setTimeout(type, typeSpeed);

    return () => clearTimeout(type as any);
  }, []);
  return (
    <>
      <b className="underline font-bold decoration-red-600">{text}</b>
    </>
  );
};

export default RunningText;
