import { Textarea } from "@/components/ui/textarea";

interface TextareaProps {
  placeholder: string;
  defaultValue?: string;
  onChange?: any;
  icon?: React.ReactNode;
}

const MyTextArea: React.FC<TextareaProps> = (props) => {
  const { placeholder, defaultValue, onChange, icon } = props;
  return (
    <div className="relative mt-2 w-full">
      <span className="w-4 h-4 absolute top-3 start-4">{icon}</span>
      <Textarea
        placeholder={placeholder}
        value={defaultValue}
        className="px-10"
        onChange={onChange}
      />
    </div>
  );
};

export default MyTextArea;
