import Image from "next/image";
import Link from "next/link";
import styles from "./Card.module.css";
import { FiArrowRight, FiAirplay } from "react-icons/fi";

interface CardProps {
  caseType: "blog" | "default";
  image?: string;
  id?: string;
  title?: string;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  link?: string;
}

const MyCard: React.FC<CardProps> = (props) => {
  const { caseType, image, id, title, content, icon, link } = props;
  switch (caseType) {
    case "blog":
      return (
        <div className="blog relative group rounded-md hover:shadow-xl transition-all duration-500 ease-in-out shadow shadow-slate-200 overflow-hidden">
          {link && (
            <Link href={link} target="_blank">
              {image && (
                <div className="w-full h-[250px]">
                  <Image
                    src={image}
                    width={100}
                    height={100}
                    alt="logo"
                    loading="lazy"
                    // placeholder="blur"
                    // blurDataURL={image}
                    className="w-full group-hover:px-4 transition-all duration-500 ease-in-out h-full object-cover"
                  />
                </div>
              )}
              <div className="content p-6">
                <h4 className="text-lg group-hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">
                  {title}
                </h4>
                <p className="text-slate-400 mt-3 max-h-[150px] overflow-hidden overflow-ellipsis">
                  {content}
                </p>
                <div className="mt-5">
                  <p className="mr-2 group-hover:mr-5 transition-all duration-500 ease-in-out inline">
                    Read More
                  </p>
                  <FiArrowRight className="inline" />
                </div>
              </div>
            </Link>
          )}
        </div>
      );
    default:
      return (
        <div className="group p-8 hover:shadow-xl text-center hover:shadow-slate-200 transition duration-500 rounded-3xl mt-8">
          {link ? (
            <Link href={link} target="_blank">
              {icon}
              <div className="mt-6">
                <h4 className="text-xl font-semibold group-hover:text-orange-600 transition-all duration-500 ease-in-out">
                  {title}
                </h4>
                <p className="text-slate-400 mt-4">{content}</p>
              </div>
            </Link>
          ) : (
            <>
              {icon}

              <div className="mt-6">
                <h4 className="text-xl font-semibold group-hover:text-orange-600 transition-all duration-500 ease-in-out">
                  {title}
                </h4>
                <p className="text-slate-400 mt-4">{content}</p>
              </div>
            </>
          )}
        </div>
      );
  }
};

export default MyCard;
