import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

interface PaginationComponentProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onNextPage,
  onPreviousPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="md:col-span-12 text-center">
      <Pagination className="inline-flex items-center -space-x-px">
        <PaginationContent>
          {currentPage > 0 && (
            <PaginationItem className="cursor-pointer" onClick={onPreviousPage}>
              <PaginationPrevious />
            </PaginationItem>
          )}
          {currentPage < totalPages - 1 && (
            <PaginationItem className="cursor-pointer" onClick={onNextPage}>
              <PaginationNext />
            </PaginationItem>
          )}
        </PaginationContent>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
