interface TitleProps {
  title: string;
  subtitle?: string;
  content?: string;
}

const Title: React.FC<TitleProps> = (props) => {
  const { title, subtitle, content } = props;
  return (
    <div className="grid grid-cols-1 pb-8 text-center">
      <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
        {subtitle}
      </h6>
      <h4 className="mb-4 mt-0 md:text-2xl text-xl font-medium dark:text-white">
        {title}
      </h4>
      <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
        {content}
      </p>
    </div>
  );
};

export default Title;
