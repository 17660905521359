import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface ButtonProps {
  children: React.ReactNode;
  size?: string;
  color?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
}

const MyButton: React.FC<ButtonProps> = (props) => {
  const { children, size, onClick, type, color } = props;
  return (
    <Button
      className={cn(
        "text-white cs-883 p-2 rounded h-11 justify-center flex items-center",
        size,
        color
      )}
      onClick={onClick}
      type={type}
      asChild
    >
      {children}
    </Button>
  );
};

export default MyButton;
